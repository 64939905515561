<template>
  <div class="content-container">
    <div
      class="customize content-square"
      v-show="getProgress >= 3 || getProgress < 6"
    >
      <div class="frame-border" v-if="!showDownloadPreview">
        <img src="../assets/global/frame.png" />
      </div>

      <div class="konva red-block" @dragover="dragover" @drop="drop">
        <v-stage :config="configKonva" ref="konva">
          <v-layer>
            <v-rect
              :config="{
                x: 0,
                y: 0,
                width: 300,
                height: 393,
                fill: '#c10100',
              }"
            />
            <!-- </v-layer> -->

            <PetImageLayer
              @stickerClick="canvasStickerClick"
              @transformEnd="handleTransformEnd"
              @dragend="handleTransformEnd"
              :pet-image="getPetImageConfig"
              :image="getImage"
            />
            <!-- <v-layer ref="stickers"> -->
            <v-image
              v-if="
                stickerConfigs.effects.image && !stickerConfigs.effects.sprite
              "
              ref="stickerEffects"
              :config="stickerEffectsConfig"
            />
            <v-sprite
              v-if="
                stickerConfigs.effects.image && stickerConfigs.effects.sprite
              "
              ref="stickerEffects"
              :config="stickerEffectsConfig"
            />
            <v-sprite
              v-if="stickerConfigs.laser.image"
              ref="stickerLaserEffect"
              :config="stickerLaserEffectConfig"
              @mousedown="canvasStickerClick"
              @touchstart="canvasStickerClick"
              @transformEnd="handleTransformEnd"
              @dragend="handleTransformEnd"
            />
            <v-sprite
              v-if="stickerConfigs.cape.image && stickerConfigs.cape.sprite"
              ref="stickerCape"
              :config="stickerCapeConfig"
              @mousedown="canvasStickerClick"
              @touchstart="canvasStickerClick"
              @transformEnd="handleTransformEnd"
              @dragend="handleTransformEnd"
            />

            <!-- <Stickers :sticker-configs="stickerConfigs" /> -->
            <template v-for="(sticker, index) in stickerConfigs">
              <v-image
                :key="index"
                v-if="sticker.image && !sticker.sprite"
                :ref="'sticker' + sticker.name"
                :config="{
                  name: sticker.name,
                  keepRatio: true,
                  resizeEnabled: true,
                  draggable: sticker.draggable,
                  rotation: sticker.rotation,
                  scale: {
                    x: sticker.scale.x,
                    y: sticker.scale.y,
                  },
                  x: sticker.x,
                  y: sticker.y,
                  width: sticker.width,
                  height: sticker.height,
                  image: sticker.image,
                }"
                @mousedown="canvasStickerClick"
                @touchstart="canvasStickerClick"
                @transformend="handleTransformEnd"
                @dragend="handleTransformEnd"
            /></template>

            <v-transformer
              ref="transformer"
              :config="{
                keepRatio: true,
                centeredScaling: true,
                enabledAnchors: [
                  'top-left',
                  'top-right',
                  'bottom-left',
                  'bottom-right',
                ],
              }"
            />
            <!-- </v-layer> -->
            <AnimatedFrame
              v-if="showDownloadPreview"
              ref="animFrame"
              :pet-name="petName"
            />
            <NameGenerator
              v-if="showDownloadPreview"
              ref="nameGen"
              :pet-name="petName"
            />
          </v-layer>
        </v-stage>
      </div>
      <div class="frame-break" v-if="!showDownloadPreview">
        <img src="../assets/global/frame_divider.png" />
      </div>
      <PhotoEditMenu
        :progress="getProgress"
        :download-preview="showDownloadPreview"
        :active-object="activeObject"
        :no-edit="noEdit"
      />

      <div
        class="add-stickers red-block"
        v-if="!petImage.draggable && !showDownloadPreview"
      >
        <div class="hint-btn prev btn" @click="handleScrollRight">
          <img src="../assets/global/icons/nav_arrow_prev.png" />
        </div>
        <div class="stickers-scrollable" ref="scrollable">
          <template v-for="(sticker, index) in stickers">
            <div
              :key="index"
              class="sticker"
              :class="{ active: draggedSticker.id === 'sticker-' + index }"
            >
              <img
                :ref="'th-sticker-' + index"
                :data-id="'sticker-' + index"
                :data-type="sticker.type"
                :data-source="'./images/stickers/full/' + sticker.full"
                :data-sprite="sticker.sprite"
                :data-steps="sticker.steps"
                :data-stickername="sticker.stickername"
                @click="stickerClick"
                @dragstart="dragStart"
                draggable="true"
                :src="'./images/stickers/th/' + sticker.image"
                class="btn"
              />
            </div>
          </template>
        </div>
        <div class="hint-btn next btn" @click="handleScrollLeft">
          <img src="../assets/global/icons/nav_arrow_next.png" />
        </div>
      </div>
    </div>

    <Navigation
      :show-download-preview="showDownloadPreview"
      :progress="progress"
      @refresh="$refs.nameGen.refresh()"
    />
    <DownloadModal
      v-if="showDownloadModal"
      :can-share="canShare"
      :use-ffmpeg="useFfmpeg"
      :is-loading="isLoading"
      :loading-txt="loadingTxt"
      :content="content.downloadModal"
      @downloadImage="downloadImage"
      @returnVideo="returnVideo"
      @shareImage="shareImage"
      @setDownloadOptions="setDownloadOptions"
      @shareGif="shareGif"
      @downloadGif="downloadGif"
    />
  </div>
</template>

<script>
import stickersData from "../content/stickers.json";

import PetImageLayer from "./konva/PetImageLayer.vue";
import NameGenerator from "./konva/NameGenerator.vue";
import AnimatedFrame from "./konva/AnimatedFrame.vue";

import PhotoEditMenu from "./partials/PhotoEditMenu.vue";
import Navigation from "./partials/Navigation.vue";
import DownloadModal from "./partials/DownloadModal.vue";

// https://github.com/yahoo/gifshot
import gifshot from "../assets/js/gifshot.min.js";

// https://github.com/ffmpegwasm
// import { createFFmpeg } from "@ffmpeg/ffmpeg";

// console.log(gifshot);

export default {
  name: "Customize",
  components: {
    PetImageLayer,
    NameGenerator,
    AnimatedFrame,
    PhotoEditMenu,
    Navigation,
    DownloadModal,
  },

  props: {
    content: null,
    image: null,
    progress: null,
    petName: null,
    chromeios: null,
    ffmpeg: null,
    useFfmpeg: null,
  },
  data() {
    return {
      createdMP4: null,

      noEdit: false,
      privacyModal: false,
      legalAgree: false,
      canvasClick: false,
      createdGif: null,
      tryShare: false,
      isLoading: false,
      loadingTxt: "LOADING...",
      canShare: true,
      showDownloadModal: false,
      gifAnimation: undefined,
      configKonva: {
        width: 300,
        height: 393,
        pixelRatio: 2,
      },
      activeFilter: null,
      activeObject: {
        id: null,
      },
      petImage: {
        name: "petImage",
        draggable: true,
        rotation: 0,
        scale: { x: 1, y: 1 },
        x: 0,
        y: 0,
        image: null,
      },
      filter: {
        name: "filters",
        draggable: false,
        rotation: 0,
        scale: { x: 1, y: 1 },
        x: 0,
        y: 0,
        image: null,
        listening: false,
      },
      draggedSticker: {
        id: null,
        source: null,
        sprite: false,
      },
      stickerConfigs: {
        mask: {
          name: "mask",
          draggable: true,
          rotation: 0,
          scale: { x: 1, y: 1 },
          x: 0,
          y: 0,
          image: null,
        },
        chest: {
          name: "chest",
          draggable: true,
          rotation: 0,
          scale: { x: 1, y: 1 },
          x: 0,
          y: 0,
          image: null,
        },
        cape: {
          name: "cape",
          draggable: true,
          rotation: 0,
          scale: { x: 0.5, y: 0.5 },
          x: 0,
          y: 0,
          image: null,
          sprite: false,
        },
        collar: {
          name: "collar",
          draggable: true,
          rotation: 0,
          scale: { x: 1, y: 1 },
          x: 0,
          y: 0,
          image: null,
        },
        effects: {
          name: "effects",
          stickername: null,
          draggable: false,
          rotation: 0,
          scale: { x: 1, y: 1 },
          x: 0,
          y: 0,
          image: null,
          sprite: true,
        },
        laser: {
          name: "laser",
          draggable: true,
          rotation: 0,
          scale: { x: 1, y: 1 },
          x: 0,
          y: 0,
          image: null,
          sprite: true,
        },
        shield: {
          name: "shield",
          draggable: false,
          rotation: 0,
          scale: { x: 1, y: 1 },
          x: 0,
          y: 0,
          image: null,
        },
      },
      stickers: stickersData,
    };
  },
  mounted() {
    console.log("stickerConfigs", this.stickerConfigs);
    // this.ffmpeg = createFFmpeg({
    //   corePath: "js/ffmpeg/ffmpeg-core.js",
    //   log: true,
    // });
    this.petImage.image = this.image;
    this.centerImage(this.petImage, true);
    this.checkiOSSafari();
    // this.canUseFFMPEG();
  },
  computed: {
    getImage() {
      // console.log("get image ", this.image);
      return this.image;
    },
    getProgress() {
      return this.progress;
    },
    showDownloadPreview() {
      // console.log("showDownloadPreview", this.progress === 5);
      return this.progress === 5;
    },
    getPetImageConfig() {
      return this.petImage;
    },
    stickerCapeConfig() {
      return {
        name: this.stickerConfigs.cape.name,
        keepRatio: true,
        resizeEnabled: true,
        draggable: this.stickerConfigs.cape.draggable,
        rotation: this.stickerConfigs.cape.rotation,
        // offset: {
        //   x: 300 / 2,
        //   y: 300 / 2,
        // },
        scale: {
          x: this.stickerConfigs.cape.scale.x,
          y: this.stickerConfigs.cape.scale.y,
        },

        // scaleX: this.stickerCape.scale.x,
        // scaleY: this.stickerCape.scale.y,
        x: this.stickerConfigs.cape.x,
        y: this.stickerConfigs.cape.y,
        width: 300,
        height: 300,
        image: this.stickerConfigs.cape.image,
        animation: "moving",
        animations: {
          moving: this.stickerConfigs.cape.steps,
        },
        frameRate: 10,
        frameIndex: 0,
      };
    },
    stickerCollarConfig() {
      return {
        name: this.stickerConfigs.collar.name,
        keepRatio: true,
        resizeEnabled: true,
        draggable: this.stickerConfigs.collar.draggable,
        rotation: this.stickerConfigs.collar.rotation,
        // offset: {
        //   x: this.stickerCollar.width / 2,
        //   y: this.stickerCollar.height / 2,
        // },
        scale: {
          x: this.stickerConfigs.collar.scale.x,
          y: this.stickerConfigs.collar.scale.y,
        },
        // scaleX: this.stickerCollar.scale.x,
        // scaleY: this.stickerCollar.scale.y,
        x: this.stickerConfigs.collar.x,
        y: this.stickerConfigs.collar.y,
        width: this.stickerConfigs.collar.width,
        height: this.stickerConfigs.collar.height,
        image: this.stickerConfigs.collar.image,
      };
    },
    stickerEffectsConfig() {
      return {
        name: this.stickerConfigs.effects.name,
        keepRatio: true,
        resizeEnabled: true,
        draggable: false,
        rotation: this.stickerConfigs.effects.rotation,
        scale: {
          x: this.stickerConfigs.effects.scale.x,
          y: this.stickerConfigs.effects.scale.y,
        },
        x: this.stickerConfigs.effects.x,
        y: this.stickerConfigs.effects.y,
        width: this.stickerConfigs.effects.width,
        height: this.stickerConfigs.effects.height,
        image: this.stickerConfigs.effects.image,
        animation: "moving",
        animations: {
          moving: this.stickerConfigs.effects.steps,
        },
        frameRate: 7,
        frameIndex: 0,
      };
    },
    stickerLaserEffectConfig() {
      return {
        name: this.stickerConfigs.laser.name,
        keepRatio: true,
        resizeEnabled: true,
        draggable: this.stickerConfigs.laser.draggable,
        rotation: this.stickerConfigs.laser.rotation,
        scale: {
          x: this.stickerConfigs.laser.scale.x,
          y: this.stickerConfigs.laser.scale.y,
        },
        x: this.stickerConfigs.laser.x,
        y: this.stickerConfigs.laser.y,
        width: this.stickerConfigs.laser.width,
        height: this.stickerConfigs.laser.height,
        image: this.stickerConfigs.laser.image,
        animation: "moving",
        animations: {
          moving: this.stickerConfigs.laser.steps,
        },
        frameRate: 7,
        frameIndex: 0,
      };
    },
    stickerShieldConfig() {
      return {
        name: this.stickerConfigs.shield.name,
        keepRatio: true,
        resizeEnabled: true,
        draggable: this.stickerConfigs.shield.draggable,
        rotation: this.stickerConfigs.shield.rotation,
        scale: {
          x: this.stickerConfigs.shield.scale.x,
          y: this.stickerConfigs.shield.scale.y,
        },
        // scaleX: this.stickerShield.scale.x,
        // scaleY: this.stickerShield.scale.y,
        x: this.stickerConfigs.shield.x,
        y: this.stickerConfigs.shield.y,
        width: this.stickerConfigs.shield.width,
        height: this.stickerConfigs.shield.height,
        image: this.stickerConfigs.shield.image,
      };
    },
  },
  methods: {
    // canUseFFMPEG() {
    //   console.log("canUseFFMPEG", typeof SharedArrayBuffer);
    //   if (typeof SharedArrayBuffer === "undefined") {
    //     this.useFFMPEG = false;
    //   }
    // },
    showPrivacy(val) {
      // console.log("toggle privacy");
      this.privacyModal = val;
    },
    checkiOSSafari() {
      const ios =
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document);

      const chromeIos = navigator.userAgent.match("CriOS");
      let isChrome = false;
      if (ios & (chromeIos == "CriOS")) isChrome = true;
      // console.log("canShare", isChrome);

      if (navigator.share && navigator.canShare && !isChrome) {
        this.canShare = true;
      } else {
        this.canShare = false;
      }
    },
    centerImage(imageObj, fullWidth, sprite) {
      const padding = 0;
      let w = imageObj.image.width;
      let h = imageObj.image.height;
      if (sprite) {
        (w = 1000), (h = 1000);
      }

      // // console.log("sprite", w, h);

      // get the aperture we need to fit by taking padding off the stage size.
      const targetW = this.$refs.konva.getStage().getWidth() - 2 * padding;
      const targetH = this.$refs.konva.getStage().getHeight() - 2 * padding;

      // compute the ratios of image dimensions to aperture dimensions
      const widthFit = targetW / w;
      const heightFit = targetH / h;

      const widthStickerFit = targetW / w / 2;
      const heightStickerFit = targetH / h / 2;

      // compute a scale for best fit and apply it
      let scale = 1;
      if (fullWidth) {
        scale = widthFit > heightFit ? heightFit : widthFit;
      } else {
        scale =
          widthStickerFit > heightStickerFit
            ? heightStickerFit
            : widthStickerFit;
      }

      w = parseInt(w * scale, 10);
      h = parseInt(h * scale, 10);
      imageObj.width = w;
      imageObj.height = h;
      // imageObj.width = this.$refs.konva.getStage().getWidth();
      // imageObj.height = this.$refs.konva.getStage().getHeight();
      if (!this.canvasClick) {
        imageObj.x = (this.$refs.konva.getStage().getWidth() - w) / 2;
        imageObj.y = (this.$refs.konva.getStage().getHeight() - h) / 2;
      }
      // imageObj.x = 2;
      // imageObj.y = 2;

      this.setActiveSticker(imageObj);
    },
    fillImage(imageObj) {
      // console.log("fillImage");
      // const padding = 0;
      let w = this.$refs.konva.getStage().getWidth();
      let h = this.$refs.konva.getStage().getHeight();

      // get the aperture we need to fit by taking padding off the stage size.
      // const targetW = this.$refs.konva.getStage().getWidth() - 2 * padding;
      // const targetH = this.$refs.konva.getStage().getHeight() - 2 * padding;

      // compute the ratios of image dimensions to aperture dimensions
      // const widthFit = targetW / w;
      // const heightFit = targetH / h;

      // compute a scale for best fit and apply it
      // let scale = widthFit < heightFit ? heightFit : widthFit;

      // w = parseInt(w * scale, 10);
      // h = parseInt(h * scale, 10);
      imageObj.cropWidth = w;
      imageObj.cropHeight = h;
      imageObj.width = w;
      imageObj.height = h;
      imageObj.scale.x = 1.14;
      imageObj.scale.y = 1.14;
      imageObj.cropX = 200;
      imageObj.cropY = 400;
      imageObj.x = -2;
      imageObj.y = 0;
      // console.log("fillImage", imageObj);
      this.setActiveSticker(imageObj);
    },
    rotateRight() {
      this.activeObject.rotation += 10;
    },
    rotateLeft() {
      this.activeObject.rotation -= 10;
    },
    scaleUp() {
      this.activeObject.scale.x += 0.05;
      this.activeObject.scale.y += 0.05;
    },
    scaleDown() {
      this.activeObject.scale.x -= 0.05;
      this.activeObject.scale.y -= 0.05;
    },
    deleteImage() {
      //if the petImage is draggable we're editing it
      if (this.petImage.draggable) {
        this.$parent.removePetImage();
        this.$parent.prevStep();
      } else {
        console.log(
          "delete",
          this.activeObject.name,
          this.$data.stickerConfigs
        );
        for (const key in this.$data.stickerConfigs) {
          //remove image from object
          if (key !== "activeObject" && this.$data.stickerConfigs[key]) {
            console.log("delete this", this.$data.stickerConfigs[key].name);
            if (
              this.$data.stickerConfigs[key].name === this.activeObject.name
            ) {
              this.$data.stickerConfigs[key].image = "";
              this.$data.stickerConfigs[key].draggable = false;
            }
          }
        }
        //resetting things
        this.clearTransformers();
        this.activeObject = {
          id: null,
          image: null,
        };
        this.draggedSticker = {
          id: null,
          source: null,
          type: null,
          stickername: null,
        };
      }
    },
    setDownloadOptions(val) {
      this.showDownloadModal = val;
      // console.log("setDownloadOptions", val);
      if (val) {
        if (this.useFfmpeg) {
          this.captureVideo();
        } else {
          this.createGif();
        }
      }
      if (!val) this.isLoading = false;
    },
    downloadImage() {
      // if (!this.legalAgree) return;

      // console.log("downloadImage", this.stickerEffects);
      if (this.$refs.stickerEffects && this.stickerEffects) {
        const saveAnimated = this.stickerEffects.image;

        if (this.stickerEffects.stickername == "lightning") {
          this.stickerEffects.sprite = false;
          const image = new window.Image();
          image.onload = () => {
            this.stickerEffects.image = image;
            this.$nextTick(() => {
              const dataURL = this.$refs.konva
                .getStage()
                .toDataURL({ pixelRatio: 2 });
              this.$parent.downloadURI(dataURL, "yoursuperpet");
              this.stickerEffects.sprite = true;
              this.stickerEffects.image = saveAnimated;
              this.$nextTick(() => {
                this.playAllAnimations();
              });
            });
          };
          image.src = "./images/stickers/full/effects/lightning.png";
        }
        if (this.stickerEffects.stickername == "flames") {
          this.stickerEffects.sprite = false;
          const image = new window.Image();
          image.onload = () => {
            this.stickerEffects.image = image;
            this.$nextTick(() => {
              const dataURL = this.$refs.konva
                .getStage()
                .toDataURL({ pixelRatio: 2 });
              this.$parent.downloadURI(dataURL, "yoursuperpet");
              this.stickerEffects.sprite = true;
              this.stickerEffects.image = saveAnimated;
              this.$nextTick(() => {
                this.playAllAnimations();
              });
            });
          };
          image.src = "./images/stickers/full/effects/flames.png";
        }
      } else {
        // this.stickerEffects.image = null;
        // this.stickerEffects.image
        const dataURL = this.$refs.konva
          .getStage()
          .toDataURL({ pixelRatio: 2 });
        this.$parent.downloadURI(dataURL, "yoursuperpet");
      }
    },
    getMimeType() {
      console.log("video/webm", MediaRecorder.isTypeSupported("video/webm"));
      console.log("video/mp4", MediaRecorder.isTypeSupported("video/mp4"));
      let thisMime = {};
      if (MediaRecorder.isTypeSupported("video/webm")) {
        thisMime = { mime: "video/webm", type: "webm" };
      } else if (MediaRecorder.isTypeSupported("video/mp4")) {
        thisMime = { mime: "video/mp4", type: "mp4" };
      }
      return thisMime;
    },
    captureVideo() {
      const userMime = this.getMimeType();
      this.isLoading = true;
      this.loadingTxt = this.content.downloadModal.loading.text + " .";
      var chunks = [];
      const canvas = document.getElementsByTagName("canvas")[0];
      // const canvas = this.$refs.konva.getStage();
      // console.log("video", canvas);
      var canvas_stream = canvas.captureStream(10); // fps
      // Create media recorder from canvas stream
      this.media_recorder = new MediaRecorder(canvas_stream, {
        mimeType: userMime.mime,
      });
      // Record data in chunks array when data is available
      this.media_recorder.ondataavailable = (evt) => {
        chunks.push(evt.data);
      };
      // Provide recorded data when recording stops
      this.media_recorder.onstop = async () => {
        // this.downloadVideoNoTranscode(chunks);

        this.loadingTxt = this.content.downloadModal.loading.text + " ..";
        this.transcode(
          new Uint8Array(await new Blob(chunks).arrayBuffer()),
          userMime.type
        );
      };
      // Start recording using a 1s timeslice [ie data is made available every 1s)
      this.media_recorder.start(1000);

      setTimeout(() => {
        this.media_recorder.stop();
      }, 2000);
    },
    downloadVideoNoTranscode(chunks) {
      // Gather chunks of video data into a blob and create an object URL
      var blob = new Blob(chunks, { type: "video/webm" });
      const recording_url = URL.createObjectURL(blob);
      // Attach the object URL to an <a> element, setting the download file name
      const a = document.createElement("a");
      a.style = "display: none;";
      a.href = recording_url;
      a.download = "yoursuperpet.webm";
      document.body.appendChild(a);
      // Trigger the file download
      a.click();
      this.isLoading = false;
      setTimeout(() => {
        // Clean up - see https://stackoverflow.com/a/48968694 for why it is in a timeout
        URL.revokeObjectURL(recording_url);
        document.body.removeChild(a);
      }, 0);
    },
    async transcode(file, fileType) {
      this.loadingTxt = this.content.downloadModal.loading.text + " ...";
      console.log("transcoding start", file);
      try {
        this.loadingTxt = this.content.downloadModal.loading.text + " ....";
        if (this.ffmpeg && !this.ffmpeg.isLoaded()) await this.ffmpeg.load();
      } catch (error) {
        console.error(error);
        this.loadingTxt = "FAILED";
        this.isLoading = false;
      }

      try {
        this.loadingTxt = this.content.downloadModal.loading.text + " .....";
        this.ffmpeg.FS("writeFile", "test." + fileType, file);
      } catch (error) {
        console.error(error);
        this.loadingTxt = "FAILED";
        this.isLoading = false;
      }

      try {
        this.loadingTxt = this.content.downloadModal.loading.text + " ......";
        await this.ffmpeg.run(
          "-i",
          "test." + fileType,
          "-vf",
          "mpdecimate",
          "-s",
          "400x400",
          "test.mp4"
        );
      } catch (error) {
        console.error(error);
        this.loadingTxt = "FAILED";
        this.isLoading = false;
      }

      try {
        const data = this.ffmpeg.FS("readFile", "test.mp4");
        const mp4Video = URL.createObjectURL(
          new Blob([data.buffer], { type: "video/mp4" })
        );
        this.isLoading = false;
        this.createdMP4 = {
          shareBlob: new Blob([data.buffer], { type: "video/mp4" }),
          downloadURL: mp4Video,
        };
      } catch (error) {
        console.error(error);
        this.loadingTxt = "FAILED";
        this.isLoading = false;
      }

      // await ffmpeg.write(name, url);
      // await ffmpeg.transcode(name, "output.mp4");
      // console.log("transcoding complete");
      // const data = ffmpeg.read("output.mp4");

      // if (isSharing) {
      //   this.shareVideo(new Blob([data.buffer], { type: "video/mp4" }));
      // } else {
      //   this.downloadVideo(mp4Video);
      // }
    },
    returnVideo(isSharing) {
      if (isSharing) {
        this.shareVideo(this.createdMP4.shareBlob);
      } else {
        this.downloadVideo(this.createdMP4.downloadURL);
      }
    },

    downloadVideo(recording_url) {
      // Attach the object URL to an <a> element, setting the download file name
      const a = document.createElement("a");
      a.style = "display: none;";
      a.href = recording_url;
      a.download = "yoursuperpet.mp4";
      document.body.appendChild(a);
      // Trigger the file download
      a.click();
      setTimeout(() => {
        // Clean up - see https://stackoverflow.com/a/48968694 for why it is in a timeout
        URL.revokeObjectURL(recording_url);
        document.body.removeChild(a);
      }, 0);
    },
    createGif() {
      // console.log("createGif", share);
      // if (share) {
      //   this.tryShare = true;
      // } else {
      //   this.tryShare = false;
      // }

      const frames = [];
      const stage = this.$refs.konva.getStage();

      const animation = new Konva.Animation(() => {
        const frameURL = this.$refs.konva
          .getStage()
          .toDataURL({ pixelRatio: 2 });
        frames.push(frameURL);
        // do nothing, animation just need to update the layer
      }, stage);

      animation.start();
      this.isLoading = true;

      setTimeout(() => {
        animation.stop();
        this.buildGif(frames, frames.length);
        // console.log("frames", frames.length);
      }, 2000);
    },
    buildGif(frames, length) {
      const that = this;
      gifshot.createGIF(
        {
          gifWidth: 600,
          gifHeight: 786,
          images: frames,
          interval: 0.05,
          numFrames: length,
          frameDuration: 1,
          sampleInterval: 1,
          numWorkers: 4,
        },
        function (obj) {
          if (!obj.error) {
            var image = obj.image;
            // var animatedImage = document.createElement("img");
            // animatedImage.src = image;
            // document.body.appendChild(animatedImage);
            that.createdGif = image;
            // console.log("createdGif", that.createdGif);

            // if (that.tryShare) {
            //   that.shareGif(image);
            // } else {
            //   that.$parent.downloadURI(image, "yoursuperpet");
            // }

            that.isLoading = false;
          }
        }
      );
    },
    downloadGif() {
      // if (!this.legalAgree) return;

      // console.log("createdGif", this.createdGif);
      this.$parent.downloadURI(this.createdGif, "yoursuperpet");
    },
    async shareVideo(dataURL) {
      // const dataURL = recording_url;
      const blob = dataURL;
      console.log("shareVideo", blob.type);
      const filesArray = [
        new File([blob], "superpets.mp4", {
          type: blob.type,
          lastModified: new Date().getTime(),
        }),
      ];
      const shareData = {
        files: filesArray,
        title: "DC League of Super-Pets",
        text: "Check out my DC SUPER-PET",
      };
      navigator.share(shareData);
    },
    async shareImage() {
      const dataURL = this.$refs.konva.getStage().toDataURL({ pixelRatio: 2 });
      // console.log("shareImage", dataURL);
      const blob = await (await fetch(dataURL)).blob();
      const filesArray = [
        new File([blob], "superpets.png", {
          type: blob.type,
          lastModified: new Date().getTime(),
        }),
      ];
      const shareData = {
        files: filesArray,
        title: "DC League of Super-Pets",
        text: "Check out my DC SUPER-PET",
      };
      navigator.share(shareData);
    },
    async shareGif() {
      // const dataURL = this.createdGif;
      console.log("shareGif", this.createdGif);

      const blob = await (await fetch(this.createdGif)).blob();
      const filesArray = [
        new File([blob], "superpet.gif", {
          type: "image/gif",
          lastModified: new Date().getTime(),
        }),
      ];
      const shareData = {
        files: filesArray,
        title: "DC League of Super-Pets",
        text: "Check out my DC SUPER-PET",
      };
      navigator.share(shareData);
    },
    handleScrollLeft() {
      let scrollable = this.$refs.scrollable;
      scrollable.scrollLeft += 50;
    },
    handleScrollRight() {
      let scrollable = this.$refs.scrollable;
      scrollable.scrollLeft -= 50;
    },
    prevStep() {
      this.clearTransformers();
      this.$parent.prevStep();
      if (this.getProgress === 5) {
        // console.log("set new height old");
        this.playAllAnimations();
        // this.configKonva.height = 343;
      } else {
        this.petImage.draggable = true;
        this.activeObject = this.petImage;

        this.stickerConfigs.mask.draggable = false;
        this.stickerConfigs.cape.draggable = false;
        this.stickerConfigs.chest.draggable = false;
        this.stickerConfigs.collar.draggable = false;
        this.stickerConfigs.effects.draggable = false;
        this.stickerConfigs.laser.draggable = false;
        this.stickerConfigs.shield.draggable = false;
      }
    },
    nextStep() {
      // this.clearTransformers();
      if (this.petImage.draggable) this.petImage.draggable = false;
      this.activeObject = {
        id: null,
      };

      this.$parent.nextStep();
      if (this.getProgress === 4) {
        this.playAllAnimations();

        // // console.log("set new height");
        // this.configKonva.height = 393;
      }
    },
    canvasStickerClick(e) {
      // this.pauseAllAnimations();
      console.log(e.evt);
      if (e.evt.cancelable) {
        e.evt.preventDefault();
      } else {
        this.isDragging = true;
      }
      this.canvasClick = true;
      this.clearTransformers();

      const stickerID = e.target.attrs.name;
      this.draggedSticker = e.target.attrs;
      // console.log("stickerID", this.draggedSticker);
      // this.draggedSticker.id = e.target.attrs.name;

      if (stickerID !== "petImage") {
        const element = this.$refs["th-" + stickerID];
        element[0].scrollIntoView({ behavior: "smooth" });
        // // console.log(element[0]);
        this.setActiveSticker(this.draggedSticker);
        element[0].click();

        // this.setActiveSticker(this.draggedSticker);
      }
      this.$nextTick(() => {
        this.updateTransformer();
      });
    },
    stickerClick(e) {
      // console.log("stickerClick", e.target.getAttribute("data-id"));
      this.clearTransformers();

      this.draggedSticker = {
        id: e.target.getAttribute("data-id"),
        source: e.target.getAttribute("data-source"),
        type: e.target.getAttribute("data-type"),
        sprite: e.target.getAttribute("data-sprite"),
        steps: e.target.getAttribute("data-steps"),
        stickername: e.target.getAttribute("data-stickername"),
      };
      this.addSticker();
    },
    dragStart(e) {
      // console.log("dragStart", e.target.getAttribute("data-id"));

      this.draggedSticker = {
        id: e.target.getAttribute("data-id"),
        source: e.target.src,
        type: e.target.getAttribute("data-type"),
        sprite: e.target.getAttribute("data-sprite"),
        steps: e.target.getAttribute("data-steps"),
      };
    },
    dragover(e) {
      e.preventDefault();
    },
    drop(e) {
      e.preventDefault();
      this.addSticker();
    },
    setActiveSticker(active) {
      console.log("activeSticker", active);
      this.cacheStickers();
      this.activeObject = active;

      if (
        active.stickername === "flames" ||
        active.stickername === "lightning"
      ) {
        this.noEdit = true;
      } else {
        this.noEdit = false;
      }

      if (active.sprite) {
        this.playAllAnimations();
      }
    },
    setStickers(sticker) {
      this.stickersArr = sticker;
      // console.log(this.stickersArr);
    },
    addSticker() {
      // console.log("addSticker", this.canvasClick);
      // if (this.canvasClick) return;
      // const fileType = this.draggedSticker.source.split(".").pop();
      const image = new window.Image();
      image.src = this.draggedSticker.source;
      image.onload = () => {
        let thisSticker;
        let shouldCenter = false;
        // set sticker in appropriate location
        switch (this.draggedSticker.type) {
          case "mask":
            thisSticker = this.stickerConfigs.mask;
            break;
          case "chest":
            thisSticker = this.stickerConfigs.chest;
            break;
          case "shield":
            thisSticker = this.stickerConfigs.shield;
            break;
          case "cape":
            thisSticker = this.stickerConfigs.cape;
            break;
          case "collar":
            thisSticker = this.stickerConfigs.collar;
            break;
          case "effects":
            thisSticker = this.stickerConfigs.effects;
            shouldCenter = true;
            break;
          case "laser":
            thisSticker = this.stickerConfigs.laser;
            shouldCenter = true;
            break;

          default:
            break;
        }
        thisSticker.image = image;
        thisSticker.draggable = true;
        thisSticker.name = this.draggedSticker.id;
        thisSticker.sprite = this.draggedSticker.sprite;
        thisSticker.stickername = this.draggedSticker.stickername;
        // console.log("thissticker", thisSticker);
        if (
          this.draggedSticker.sprite &&
          this.draggedSticker.type === "effects"
        ) {
          thisSticker.steps = this.draggedSticker.steps.split(",");
          this.fillImage(thisSticker);
          this.clearTransformers();
        } else if (this.draggedSticker.sprite) {
          thisSticker.steps = this.draggedSticker.steps.split(",");
          this.centerImage(thisSticker, shouldCenter, true);
        } else {
          this.centerImage(thisSticker, shouldCenter);
        }
        this.$nextTick(() => {
          this.setActiveSticker(thisSticker);
          this.updateTransformer();
        });
      };
    },
    cacheStickers() {
      // // console.log(this.$refs.stickerCollar.getNode().attrs.width);
      if (
        this.$refs.stickercollar &&
        this.$refs.stickercollar.getNode().attrs.width
      ) {
        this.$refs.stickercollar.getNode().cache();
        this.$refs.stickercollar.getNode().drawHitFromCache();
      }
      // if (
      //   this.$refs.stickerCape &&
      //   this.$refs.stickerCape.getNode().attrs.width
      // ) {
      //   this.$refs.stickerCape.getNode().cache();
      //   this.$refs.stickerCape.getNode().drawHitFromCache();
      // }
      if (
        this.$refs.stickerchest &&
        this.$refs.stickerchest.getNode().attrs.width
      ) {
        this.$refs.stickerchest.getNode().cache();
        this.$refs.stickerchest.getNode().drawHitFromCache();
      }
      if (
        this.$refs.stickermask &&
        this.$refs.stickermask.getNode().attrs.width
      ) {
        this.$refs.stickermask.getNode().cache();
        this.$refs.stickermask.getNode().drawHitFromCache();
      }
      // if (
      //   this.$refs.stickerEffects &&
      //   this.$refs.stickerEffects.getNode().attrs.width
      // ) {
      //   this.$refs.stickerEffects.getNode().cache();
      //   this.$refs.stickerEffects.getNode().drawHitFromCache();
      // }
    },
    handleTransformEnd(e) {
      // console.log("handleTransformEnd", e.target);
      // console.log("handleTransformEnd", this.activeObject);
      // this.canvasClick = false;
      // shape is transformed, let us save new attrs back to the node
      // update the state
      // this.activeObject.x = e.target.x() - this.activeObject.width / 2;
      // this.activeObject.y = e.target.y() - this.activeObject.height / 2;
      this.activeObject.x = e.target.x();
      this.activeObject.y = e.target.y();

      this.activeObject.rotation = e.target.rotation();

      if (this.activeObject.scale) {
        this.activeObject.scale.x = e.target.scaleX();
        this.activeObject.scale.y = e.target.scaleY();
      } else {
        this.activeObject.scaleX = e.target.scaleX();
        this.activeObject.scaleY = e.target.scaleY();
      }
      // console.log("handleTransformEnd", this.stickerChest);

      this.playAllAnimations();
    },
    updateTransformer() {
      // console.log("updateTransformer", this.activeObject);
      if (!this.activeObject.draggable) return;
      // here we need to manually attach or detach Transformer node
      const transformerNode = this.$refs.transformer.getNode();
      // // console.log("transformerNode", transformerNode);
      const stage = transformerNode.getStage();

      const selectedNode = stage.findOne("." + this.activeObject.name);
      // console.log("selectedNode", this.activeObject);
      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return;
      }

      if (selectedNode) {
        // attach to another node
        transformerNode.nodes([selectedNode]);
      } else {
        // remove transformer
        transformerNode.nodes([]);
      }
    },
    clearTransformers() {
      const transformerNode = this.$refs.transformer.getNode();
      transformerNode.nodes([]);
    },
    playAllAnimations() {
      // console.log("playAllAnimations");
      this.$nextTick(() => {
        if (this.$refs.stickerEffects) {
          const node = this.$refs.stickerEffects.getNode();
          node.start();
        }
        if (this.$refs.stickerCape) {
          const cape = this.$refs.stickerCape.getNode();
          cape.start();
        }
        if (this.$refs.stickerLaserEffect) {
          const cape = this.$refs.stickerLaserEffect.getNode();
          cape.start();
        }
      });
    },
    pauseAllAnimations() {
      this.$nextTick(() => {
        if (this.$refs.stickerEffects) {
          const node = this.$refs.stickerEffects.getNode();
          node.stop();
        }
        if (this.$refs.stickerCape) {
          const node = this.$refs.stickerCape.getNode();
          node.stop();
        }
        if (this.$refs.stickerLaserEffect) {
          const cape = this.$refs.stickerLaserEffect.getNode();
          cape.stop();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.content-container {
  @media screen and (max-width: 768px) {
    transform: scale(0.8) translateY(-10%);
  }
}
.customize {
}
.konvajs {
  display: flex;
  justify-content: center;
}
.add-stickers {
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;

  background: #4b0100;
  box-shadow: inset #7c0100 10px 19px 17px 0px;

  .edit-icon {
    width: 30px;
    img {
      width: 100%;
    }
  }
}
.add-stickers {
  overflow: hidden;
  .stickers-scrollable {
    margin: 0 35px;
    height: 50px;
    width: 301px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-y: hidden;
    overflow-x: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .hint-btn {
    width: 25px;
    position: absolute;
    top: 0;
    height: 47px;
    display: flex;
    align-items: center;
    padding: 0 5px;

    img {
      width: 100%;
    }
    &.prev {
      left: 3px;
    }
    &.next {
      right: 3px;
    }
  }
  .sticker {
    height: 45px;
    width: 49px;
    display: flex;
    align-items: center;
    margin: 0 2px;
    padding: 2px 8px;

    img {
      height: 80%;
      width: auto;
      margin-bottom: 8px;
    }
    &.active {
      border: 2px solid #ebc153;
      padding: 0 5px;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}
.frame-break {
  margin-top: -53px;
}
.download-modal,
.legal-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-content {
    border: 5px solid #e9c666;
    // background: url("../assets/template/modal_bg.png");
    // background-size: contain;
    width: 80vw;
    max-width: 350px;
    height: auto;
    max-height: 240px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    position: relative;
    .content-holder {
      height: 100%;
      width: 100%;
      color: #fee18b;
      .title {
        margin: 10px 0;
        font-size: 20px;
        &.download-only {
          font-size: 16px;
        }
      }
      .legal-content {
      }
    }
    .close-modal {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 30px;
      z-index: 1;
      img {
        width: 100%;
      }
    }
    .download-options {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: 15px;
      .options {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        pointer-events: none;
        opacity: 0.6;
        &.active {
          pointer-events: auto;
          opacity: 1;
        }
      }
      .image,
      .video {
        width: 50px;
        margin: 0 10px;
        font-size: 12px;
        img {
          width: 100%;
        }
      }
    }
    .share-txt {
      margin-top: 16px;
      font-size: 12px;
      color: #fff;
      .hashtag {
        color: #fee18b;
      }
    }
    .legal-txt {
      font-size: 9px;
      color: #fff;
      position: relative;
      display: inline;
      .show-privacy {
        display: inline;
        text-decoration: underline;
      }
      label {
        cursor: pointer;
      }
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        position: absolute;
        top: 0px;
        left: -15px;
        height: 7px;
        width: 7px;
        background-color: #9f0101;
        border: 2px solid #dba03d;
        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }
      input:checked ~ .checkmark {
      }
      input:checked ~ .checkmark:after {
        display: block;
      }
      .checkmark:after {
        left: 3px;
        top: -7px;
        width: 5px;
        height: 10px;
        border: solid #fee18a;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
    .inst-txt {
      font-size: 8px;
      color: #fff;
    }
    .loading {
      display: flex;
      width: calc(100% - 13px);
      height: calc(100% - 11px);
      position: absolute;
      align-items: center;
      justify-content: center;
      background: #be0000c7;
      top: 6px;
      left: 6px;
    }
  }
}
.legal-modal {
  .modal-content {
    .content-holder {
      overflow-y: scroll;
      margin: 20px;
      box-sizing: border-box;
      width: 80%;
      text-align: left;
      font-weight: 400;
      font-size: 12px;
      scrollbar-color: #eabd44 #000;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        display: block;
        width: 5px;
        padding-right: 10px;
      }
      &::-webkit-scrollbar-track {
        background: #000;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #eabd44;
        border-radius: 10px;
      }
      a {
        color: #fff;
      }
    }
  }
}
</style>

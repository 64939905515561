<template>
  <div class="download-modal">
    <div class="modal-content red-block montserrat black">
      <div class="content-holder">
        <div
          class="title"
          v-if="canShare"
          :style="'font-size:' + content.title.size"
        >
          <span v-html="content.title.text"></span>
        </div>
        <div
          class="title download-only"
          v-if="!canShare"
          :style="'font-size:' + content.subtitle.size"
        >
          <span v-html="content.subtitle.text"></span>
        </div>
        <div class="download-options">
          <div class="download-download">
            <div
              class="options-title"
              v-if="canShare"
              :style="'font-size:' + content.download.size"
            >
              {{ content.download.text }}
            </div>
            <div class="options active">
              <div
                class="image btn"
                @click="downloadImage"
                :style="'font-size:' + content.image.size"
              >
                <img src="../../assets/global/icons/icon_download_static.png" />
                {{ content.image.text }}
              </div>
              <div
                v-if="useFfmpeg"
                class="video btn"
                :style="'font-size:' + content.video.size"
                @click="returnVideo()"
              >
                <img
                  src="../../assets/global/icons/icon_download_animated.png"
                />
                {{ content.video.text }}
              </div>
              <div
                v-else
                class="video btn"
                :style="'font-size:' + content.gif.size"
                @click="downloadGif(false)"
              >
                <img
                  src="../../assets/global/icons/icon_download_animated.png"
                />
                {{ content.gif.text }}
              </div>
            </div>
          </div>
          <div class="download-share" v-if="canShare">
            <div
              class="options-title"
              :style="'font-size:' + content.share.size"
            >
              {{ content.share.text }}
            </div>
            <div class="options active">
              <div
                class="image btn"
                @click="shareImage"
                :style="'font-size:' + content.image.size"
              >
                <img src="../../assets/global/icons/icon_download_static.png" />
                {{ content.image.text }}
              </div>
              <div
                v-if="useFfmpeg"
                class="video btn"
                @click="returnVideo(true)"
                :style="'font-size:' + content.video.size"
              >
                <img
                  src="../../assets/global/icons/icon_download_animated.png"
                />
                {{ content.video.text }}
              </div>
              <div
                v-else
                class="video btn"
                @click="shareGif(true)"
                :style="'font-size:' + content.gif.size"
              >
                <img
                  src="../../assets/global/icons/icon_download_animated.png"
                />
                {{ content.gif.text }}
              </div>
            </div>
          </div>
        </div>
        <div class="share-txt" :style="'font-size:' + content.hashtag.size">
          <span v-html="content.hashtag.text"></span>
        </div>
        <div
          class="inst-txt montserrat light"
          :style="'font-size:' + content.instructions.size"
        >
          {{ content.instructions.text }}
        </div>
      </div>
      <div class="close-modal btn" @click="setDownloadOptions(false)">
        <img src="../../assets/global/icons/icon_close.png" />
      </div>
      <div
        class="loading"
        :style="'font-size:' + content.loading.size"
        v-if="isLoading"
      >
        {{ loadingTxt }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownloadModal",
  data() {
    return {};
  },
  props: {
    content: null,
    canShare: null,
    useFfmpeg: null,
    isLoading: null,
    loadingTxt: null,
  },
  mounted() {},
  methods: {
    downloadImage() {
      this.$emit("downloadImage");
    },
    returnVideo(val) {
      this.$emit("returnVideo", val);
    },
    shareImage() {
      this.$emit("shareImage");
    },
    setDownloadOptions(val) {
      this.$emit("setDownloadOptions", val);
    },
    downloadGif(val) {
      this.$emit("downloadGif", val);
    },
    shareGif(val) {
      this.$emit("shareGif", val);
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.download-modal,
.legal-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-content {
    border: 5px solid #e9c666;
    // background: url("../assets/template/modal_bg.png");
    // background-size: contain;
    width: 80vw;
    max-width: 350px;
    height: auto;
    max-height: 240px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    position: relative;
    .content-holder {
      height: 100%;
      width: 100%;
      color: #fee18b;
      .title {
        margin: 10px 0;
        font-size: 20px;
        &.download-only {
          font-size: 16px;
        }
      }
      .legal-content {
      }
    }
    .close-modal {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 30px;
      z-index: 1;
      img {
        width: 100%;
      }
    }
    .download-options {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: 15px;
      .options {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        pointer-events: none;
        opacity: 0.6;
        &.active {
          pointer-events: auto;
          opacity: 1;
        }
      }
      .image,
      .video {
        width: 50px;
        margin: 0 10px;
        font-size: 12px;
        img {
          width: 100%;
        }
      }
    }
    .share-txt {
      margin-top: 16px;
      font-size: 12px;
      color: #fff;
      .hashtag {
        color: #fee18b;
      }
    }
    .legal-txt {
      font-size: 9px;
      color: #fff;
      position: relative;
      display: inline;
      .show-privacy {
        display: inline;
        text-decoration: underline;
      }
      label {
        cursor: pointer;
      }
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        position: absolute;
        top: 0px;
        left: -15px;
        height: 7px;
        width: 7px;
        background-color: #9f0101;
        border: 2px solid #dba03d;
        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }
      input:checked ~ .checkmark {
      }
      input:checked ~ .checkmark:after {
        display: block;
      }
      .checkmark:after {
        left: 3px;
        top: -7px;
        width: 5px;
        height: 10px;
        border: solid #fee18a;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
    .inst-txt {
      font-size: 8px;
      color: #fff;
    }
    .loading {
      display: flex;
      width: calc(100% - 13px);
      height: calc(100% - 11px);
      position: absolute;
      align-items: center;
      justify-content: center;
      background: #be0000c7;
      top: 6px;
      left: 6px;
    }
  }
}
</style>
